<template>
  <div :class="classList.container">
    <!-- this input is for FormData -->
    <div :class="classList.formInputContainer">
      <input
        :id="id"
        :class="classList.formInput"
        :type="inputType.TEXT"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :name="name"
        v-model="innerValue"
      />
    </div>

    <!-- these inputs are for UI -->
    <div :class="classList.layoutContainer" :name="name">
      <label v-if="label" :for="id" :class="derivedLabelClass">
        {{ label }}
      </label>
      <Balloon :text="message" :arrowPosition="balloonArrowPosition" />

      <div :class="classList.inputContainer">
        <span class="csn-money-field-sidelabel" v-if="sideLabel">
          {{ sideLabel }}
        </span>

        <input
          :class="classList.integer"
          :type="inputType.TEXT"
          :placeholder="integerPlaceholder"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          :name="name"
          v-model="integerValue"
          @input="handleIntegerInput($event.target.value)"
          @focus="handleInputFocus(focusedInputEnum.INTEGER)"
          @blur="handleInputBlur($event.target.value)"
        />
        <div v-if="hasDecimal" :class="classList.comma">,</div>
        <input
          v-if="hasDecimal"
          :class="classList.decimal"
          :type="inputType.TEXT"
          :placeholder="decimalPlaceholder"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          :name="name"
          v-model="fractionalValue"
          @input="handleFractionInput($event.target.value)"
          @focus="handleInputFocus(focusedInputEnum.FRACTION)"
          @blur="handleInputBlur($event.target.value)"
        />
      </div>

      <span v-if="description" :class="classList.description">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  BalloonArrowPosition,
  CSN_INPUT_CLASS,
  INPUT_CONTAINER_STYLE_CLASS,
  Digit,
  EMPTY_STRING,
  MONEY_FIELD,
  OFF,
} from '@/constants'
import {
  pipe,
  getFraction,
  extractNumberFromString,
  removeLeadingZero,
  when,
  always,
} from '@/helpers'
import { inputMixin } from '@/mixins'

const FORM_CONTROL = 'form-control form-control'
const CSN_MONEY_FIELD = 'csn-money-field'
const CSN_MONEY_FIELD_INPUT = `${CSN_MONEY_FIELD}-input`
const CSN_MONEY_FIELD_WRAPPER = `${CSN_MONEY_FIELD}-wrapper`
const CSN_MONEY_FIELD_INTEGER_INPUT = `${CSN_MONEY_FIELD}-integer-input`
const CSN_MONEY_FIELD_COMMA = `${CSN_MONEY_FIELD}-comma`
const CSN_MONEY_FIELD_FRACTIONAL_INPUT = `${CSN_MONEY_FIELD}-fractional-input`
const CSN_MONEY_FIELD_DESCRIPTION = `${CSN_MONEY_FIELD}-description`
const CSN_MONEY_FIELD_LAYOUT_WITH_SIDE_LABEL = `${CSN_MONEY_FIELD}-layout-with-side-label`

const focusedInputEnum = {
  NONE: null,
  INTEGER: 'INTEGER',
  FRACTION: 'FRACTION',
}

export default {
  name: MONEY_FIELD,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
  },
  mixins: [inputMixin],
  data: () => ({
    integerValue: EMPTY_STRING,
    fractionalValue: EMPTY_STRING,
    focusedInput: focusedInputEnum.NONE,
  }),
  props: {
    readonly: Boolean,
    description: String,
    sideLabel: String,
    integerInputClass: String,
    fractionInputClass: String,
    autocomplete: {
      type: String,
      default: OFF,
    },
    hasDecimal: Boolean,
  },
  computed: {
    classList() {
      return {
        container: [CSN_MONEY_FIELD, CSN_INPUT_CLASS],
        formInputContainer: [CSN_MONEY_FIELD_INPUT],
        formInput: [this.inputClass],
        layoutContainer: [
          INPUT_CONTAINER_STYLE_CLASS,
          this.containerClass,
          this.sideLabel
            ? CSN_MONEY_FIELD_LAYOUT_WITH_SIDE_LABEL
            : EMPTY_STRING,
        ],
        inputContainer: CSN_MONEY_FIELD_WRAPPER,
        integer: [
          FORM_CONTROL,
          CSN_MONEY_FIELD_INTEGER_INPUT,
          this.integerInputClass,
        ],
        comma: CSN_MONEY_FIELD_COMMA,
        decimal: [
          FORM_CONTROL,
          CSN_MONEY_FIELD_FRACTIONAL_INPUT,
          this.fractionInputClass,
        ],
        description: CSN_MONEY_FIELD_DESCRIPTION,
      }
    },
    balloonArrowPosition: () => BalloonArrowPosition.BOTTOM_LEFT,
    derivedValue() {
      const integer = pipe(
        Number,
        when(Number.isNaN, always(Digit.NOUGHT)),
      )(this.integerValue)
      const fraction = pipe(
        Number,
        when(Number.isNaN, always(Digit.NOUGHT)),
        (value) => value / Digit.ONE_HUNDRED,
      )(this.fractionalValue)

      return integer + fraction
    },
    focusedInputEnum: () => focusedInputEnum,
    integerPlaceholder() {
      const placeholder = Number(this.placeholder)

      return isFinite(placeholder)
        ? String(Math.trunc(placeholder))
        : EMPTY_STRING
    },
    decimalPlaceholder() {
      const placeholder = Number(this.placeholder)
      const p = Math.abs(placeholder)
      const decimal = p - Math.floor(p)
      const decimalPlaceholder = String(decimal.toFixed(2)).substring(2)

      return isFinite(placeholder) ? decimalPlaceholder : EMPTY_STRING
    },
  },
  watch: {
    immediate: true,
    innerValue: {
      handler(floatValue) {
        if (this.focusedInput !== focusedInputEnum.INTEGER) {
          this.integerValue = this.getIntegerValue(floatValue)
        }
        if (this.focusedInput !== focusedInputEnum.FRACTION) {
          this.fractionalValue = this.getFractionalValue(floatValue)
        }
      },
    },
    derivedValue(value) {
      this.handleInput(value)
    },
  },
  methods: {
    getIntegerValue(value) {
      return pipe(Number, Math.trunc)(value)
    },
    getFractionalValue(value) {
      return pipe(
        Number,
        getFraction,
        Math.round,
        when((value) => value === Digit.NOUGHT, always(EMPTY_STRING)),
      )(value)
    },
    handleIntegerInput(value) {
      this.integerValue = pipe(
        extractNumberFromString,
        removeLeadingZero,
      )(value)
    },
    handleFractionInput(value) {
      this.fractionalValue = pipe(extractNumberFromString, (v) =>
        v.slice(Digit.NOUGHT, Digit.TWO),
      )(value)
    },
    handleInputBlur() {
      this.focusedInput = focusedInputEnum.NONE
      this.handleBlur(this.derivedValue)
    },
    handleInputFocus(inputName) {
      this.focusedInput = inputName
      this.handleFocus()
    },
  },
}
</script>
